// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
    align-items: center;
    height: 100%;
    font-size: 1.0em;
    color: black;
    min-height: 200px;
}

.about-container label {
    display: block; /* Ensure the label takes up its own line */
    margin-top: 20px; /* Add some space above the label */
    font-size: 0.9em; /* Adjust the font size slightly */
    color: black; /* Add a color to make the label/link stand out */
}

.about-container label a {
    text-decoration: none; /* Remove underline from the link */
    color: inherit; /* Inherit the color from the label */
    color: #0073e6;
}

.about-container label a:hover {
    text-decoration: underline; /* Underline on hover for clarity */
}

.about-container .info {
    text-align: justify;
  }
`, "",{"version":3,"sources":["webpack://./src/Components/businessprofile/about.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,cAAc,EAAE,2CAA2C;IAC3D,gBAAgB,EAAE,mCAAmC;IACrD,gBAAgB,EAAE,kCAAkC;IACpD,YAAY,EAAE,iDAAiD;AACnE;;AAEA;IACI,qBAAqB,EAAE,mCAAmC;IAC1D,cAAc,EAAE,qCAAqC;IACrD,cAAc;AAClB;;AAEA;IACI,0BAA0B,EAAE,mCAAmC;AACnE;;AAEA;IACI,mBAAmB;EACrB","sourcesContent":[".about-container {\n    align-items: center;\n    height: 100%;\n    font-size: 1.0em;\n    color: black;\n    min-height: 200px;\n}\n\n.about-container label {\n    display: block; /* Ensure the label takes up its own line */\n    margin-top: 20px; /* Add some space above the label */\n    font-size: 0.9em; /* Adjust the font size slightly */\n    color: black; /* Add a color to make the label/link stand out */\n}\n\n.about-container label a {\n    text-decoration: none; /* Remove underline from the link */\n    color: inherit; /* Inherit the color from the label */\n    color: #0073e6;\n}\n\n.about-container label a:hover {\n    text-decoration: underline; /* Underline on hover for clarity */\n}\n\n.about-container .info {\n    text-align: justify;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
