import React  from 'react'
import { useParams } from 'react-router-dom';
import { useState , useEffect } from 'react';





export default function CityLandingpage() {
    const { city } = useParams();
    const apipath = process.env.REACT_APP_API_URL
    const stylepath = process.env.REACT_APP_STYLE_URL
    
   

        // Ensure localStorage values are updated
        const [lat, setLat] = useState(null);
        const [long, setLong] = useState(null);
        const [showTopBusiness, setShowTopBusiness] = useState(false); // State to control when to show the component

    
        useEffect(() => {
            // Use setTimeout to introduce a 3-second delay before retrieving lat/long
            const timeoutId = setTimeout(() => {
                const storedLat = localStorage.getItem('lat');
                const storedLong = localStorage.getItem('long');
                setLat(storedLat);
                setLong(storedLong);
            }, 3000); // 3000 ms = 3 seconds
    
            return () => clearTimeout(timeoutId); // Clean up the timeout if the component unmounts
        }, []);

        useEffect(() => {
            // Delay the display of the TopBusiness component by 4 seconds
            const delayId = setTimeout(() => {
                setShowTopBusiness(true);
            }, 3000); // 4000 ms = 4 seconds
    
            return () => clearTimeout(delayId); // Clean up the timeout if the component unmounts
        }, []);
    
      

   

    return (
        <><div>
           <div> <web-banner-component bannerApi={apipath + "banner"} styleLink={stylepath}> </web-banner-component></div>
           <div></div> <web-services-component servicesApi={apipath + "getServiceType"} styleLink={stylepath}></web-services-component>

           <div> <web-topcategory-component topcategoryapi={apipath + "getServiceType"} styleLink={stylepath}></web-topcategory-component></div>
           <div>
                {showTopBusiness && (
                    <web-topbusiness-component
                        lat={lat}
                        long={long}
                        getTopBusiness={apipath + "getTopBusinessByCity"}
                        styleLink={stylepath}
                    ></web-topbusiness-component>
                )}
            </div>
           <div><web-citylist-component
                localityApi={apipath + "CityWiseAreaBusinessCount"}
                styleLink={stylepath}
                city={city}

            >
            </web-citylist-component> </div>
          <div>  <web-downloadapp-component
                styleLink={stylepath}>
            </web-downloadapp-component> </div> 
           <div> <web-footer-component
                styleLink={stylepath}>
            </web-footer-component> 
            </div>
            </div>

        </>
    )
}
