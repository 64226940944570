import React, { useEffect, useState } from 'react';

const AppleSignInButton = () => {
    const [authCode, setAuthCode] = useState(null);
    const [idToken, setIdToken] = useState(null);

    useEffect(() => {
        if (authCode && idToken) {
            // Store tokens in local storage
            localStorage.setItem('apple', JSON.stringify({ code: authCode, id_token: idToken }));

            // Exchange authorization code for tokens
            exchangeAuthorizationCode(authCode).then(tokens => {
                if (tokens?.access_token) {
                    fetchUserDetails(tokens.access_token).then(userInfo => {
                        console.log('User Info:', userInfo);
                    });
                }
            });
        }
    }, [authCode, idToken]);

    const handleAppleSignIn = () => {
        const clientId = 'app.whizzq.stagingbiz'; // Replace with your client ID
        const redirectURI = 'https://stagingbiz.whizzq.app/l'; // Replace with your redirect URI
        const state = 'random-string-to-protect-against-csrf';
        const scope = 'name email';

        const url = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectURI)}&state=${state}&scope=${scope}&response_mode=form_post`;

        const popup = window.open(url, 'apple-login', 'width=500,height=600');

        window.addEventListener('message', (event) => {
            if (event.origin !== window.location.origin) {
                // Ignore messages from unknown origins
                return;
            }

            const { code, id_token } = event.data;
            if (code && id_token) {
                setAuthCode(code);
                setIdToken(id_token);
                popup.close();
            }
        });
    };

    const exchangeAuthorizationCode = async (code) => {
        const params = new URLSearchParams();
        params.append('client_id', 'app.whizzq.stagingbiz'); // Replace with your client ID
        params.append('client_secret', '3ZSSP4U9FK'); // Replace with your client secret
        params.append('code', code);
        params.append('grant_type', 'authorization_code');
        params.append('redirect_uri', 'https://stagingbiz.whizzq.app/l'); // Replace with your redirect URI

        try {
            const response = await fetch('https://appleid.apple.com/auth/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString(),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const data = await response.json();
            console.log('Tokens:', data);
            return data;
        } catch (error) {
            console.error('Error exchanging code:', error);
        }
    };

    const fetchUserDetails = async (accessToken) => {
        try {
            const response = await fetch('https://appleid.apple.com/auth/userinfo', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }

            const userInfo = await response.json();
            return userInfo;
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    return (
        <div>
            <button onClick={handleAppleSignIn} style={{ background: '#000', color: '#fff', padding: '10px 20px', border: 'none', borderRadius: '5px' }}>
                Sign in with Apple
            </button>
        </div>
    );
};

export default AppleSignInButton;


