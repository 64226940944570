// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ConfirmationModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modales-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 40%;
    height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.confirm-button {
    background: #1D3557;
    color: white;
}

.cancel-button {
    background: red;
    color: white;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .modales-content {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .modales-content {
        width: 90%;
        height: auto;
        padding: 10px;
    }

    .confirm-button,
    .cancel-button {
        padding: 8px 16px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/My Appointments/Model.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;;AAElC;;AAEA;IACI,aAAa;IACb,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA,qCAAqC;AACrC;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;QACV,YAAY;QACZ,aAAa;IACjB;;IAEA;;QAEI,iBAAiB;IACrB;AACJ","sourcesContent":["/* ConfirmationModal.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.modales-content {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    text-align: center;\n    width: 40%;\n    height: 190px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    \n}\n\n.modal-buttons {\n    display: flex;\n    justify-content: space-around;\n    margin-top: 20px;\n}\n\n.confirm-button,\n.cancel-button {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.confirm-button {\n    background: #1D3557;\n    color: white;\n}\n\n.cancel-button {\n    background: red;\n    color: white;\n}\n\n/* Media queries for responsiveness */\n@media (max-width: 768px) {\n    .modales-content {\n        width: 80%;\n    }\n}\n\n@media (max-width: 480px) {\n    .modales-content {\n        width: 90%;\n        height: auto;\n        padding: 10px;\n    }\n\n    .confirm-button,\n    .cancel-button {\n        padding: 8px 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
