import React, { useContext, useState } from 'react';
import './contact.css';
import { useServiceContext } from '../contextapi/Servicecontext';

export default function ContactModal({ onClose }) {



    const { buisnessid, setBusinessid, translations, businessname, businessnumber  } = useServiceContext()


    const [mobileNumber, setMobileNumber] = useState('');
    const [isMobileError, setIsMobileError] = useState('');
    const [otploader, setOtpLoader] = useState(false);
    const [email, setEmail] = useState('')
    const [isEmailError, setIsEmailError] = useState('');
    const [name, setName] = useState('');
    const [isBusinessError, setIsBusinessError] = useState('')
    const [apiResponse, setApiResponse] = useState(null);


    const ButtonStyleotp = {
        // Add your styles for the disabled button here
    };

    const handleButtonClick = async () => {
        // Validate name
        if (!name.trim() || !mobileNumber.match(/^[0-9]{10}$/) || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            if (!name.trim()) {
                setIsBusinessError('Please enter your name');
            }
            if (!mobileNumber.match(/^[0-9]{10}$/)) {
                setIsMobileError('Please enter a valid 10-digit mobile number');
            }
            if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                setIsEmailError('Please enter a valid email address');
            }
            return;
        }
        

        // Clear previous error messages
        setIsBusinessError('');
        setIsMobileError('');
        setIsEmailError('');

        // Data to be sent in the request
        const requestData = {
            "bo_id": buisnessid,
            "email": "",
            "businessname": businessname,
            "number": businessnumber,
            "customer_id": "",
            "customer_number": mobileNumber,
            "customer_email": email,
            "customer_name": name
        };
        try {
            // Make a POST request to the API endpoint
             const api = process.env.REACT_APP_API_URL + 'businessRequest'

            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Handle the response data here
            const data = await response.json();
            setApiResponse(data);


            // Add your logic to process the data or update the UI
        } catch (error) {
            // Handle errors
            console.error('Fetch error:', error);
        }
    };



    return (


        <div >


            {apiResponse && apiResponse.status ? (<div className='contact-msg'>{apiResponse.message}.</div>) :

                (<div className='cntc-modal-border'>
                    <div style={{ marginLeft: '65px', fontSize: '19px', float: 'right', zIndex: '999' , position: 'relative' }}>
                        <span>
                            <i className="fas fa-times" onClick={onClose}  ></i>
                        </span>
                    </div>

                    <div>

                        <div>

                            <span className='cntc-form-txt'>{translations.Name}</span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={translations.name}
                                value={name}
                                onChange={(e) => {

                                    setName(e.target.value);
                                    

                                }}
                                required
                            />
                            <div style={{ color: 'red' }}>{isBusinessError}</div>
                        </div>
                        <br />

                        <div>
                            <span className='cntc-form-txt'>{translations.mobilenumber}</span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={translations.mobile}
                                value={mobileNumber}
                                onChange={(e) => {

                                    setMobileNumber(e.target.value);
                                }}
                                required
                            />
                            <div style={{ color: 'red' }}>{isMobileError}</div>
                        </div>
                        <br />

                        <div>

                            <span className='cntc-form-txt' >{translations.Email}</span>

                            <input
                                type="text"
                                className="form-control"
                                placeholder={translations.email}
                                value={email}
                                onChange={(e) => {

                                    setEmail(e.target.value);
                                }}

                                required
                            />
                            <div style={{ color: 'red' }}>{isEmailError}</div>
                        </div>




                    </div>


                    <br />


                    <button type="button" className="btn btn-primary send-otp" onClick={handleButtonClick}>
                        {translations.contact}
                    </button>

                </div>

                )}
        </div>


    );
}
