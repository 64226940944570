// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* contact.css */


.overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
    
  }
  
   .contact-msg{
     
     font-size: 10px;
     font-weight: 400;
     font-family: 'Poppins';
     color: green;
     text-wrap: wrap;
     width: 121px;
     line-height: 8px;
 }

  
  .cntc-modal-border{
         border: 1px solid #1D3557;
         border-radius: 8px;
        background-color: white;
        padding:9px;
        margin:4px;
        position: absolute;
        z-index: 999;

  }
  .cntc-form-txt{
       
       color:#1D3557;
       font-weight: 450;


  }
`, "",{"version":3,"sources":["webpack://./src/Components/businesslisting/contact.css"],"names":[],"mappings":"AAAA,gBAAgB;;;AAIhB;IACI,oCAAoC,EAAE,4CAA4C;;EAEpF;;GAEC;;KAEE,eAAe;KACf,gBAAgB;KAChB,sBAAsB;KACtB,YAAY;KACZ,eAAe;KACf,YAAY;KACZ,gBAAgB;CACpB;;;EAGC;SACO,yBAAyB;SACzB,kBAAkB;QACnB,uBAAuB;QACvB,WAAW;QACX,UAAU;QACV,kBAAkB;QAClB,YAAY;;EAElB;EACA;;OAEK,aAAa;OACb,gBAAgB;;;EAGrB","sourcesContent":["/* contact.css */\n@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');\n\n\n.overlay {\n    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */\n    \n  }\n  \n   .contact-msg{\n     \n     font-size: 10px;\n     font-weight: 400;\n     font-family: 'Poppins';\n     color: green;\n     text-wrap: wrap;\n     width: 121px;\n     line-height: 8px;\n }\n\n  \n  .cntc-modal-border{\n         border: 1px solid #1D3557;\n         border-radius: 8px;\n        background-color: white;\n        padding:9px;\n        margin:4px;\n        position: absolute;\n        z-index: 999;\n\n  }\n  .cntc-form-txt{\n       \n       color:#1D3557;\n       font-weight: 450;\n\n\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
