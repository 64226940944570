// ConfirmationModal.js
import React from 'react';
import './Model.css';

const ConfirmationModal = ({ show, onConfirm, onCancel }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay">
            <div className="modales-content">
                <h4>Do you want to permanently cancel this Appointment? </h4>
                <div className="modal-buttons">
                    <button className="confirm-button" onClick={onConfirm}>YES, CANCEL IT!</button>
                    <button className="cancel-button" onClick={onCancel}>CANCEL</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
