import React, { useState, useEffect } from "react";
import { useOtpInput } from "react-otp-input-hook";
import { useServiceContext } from '../contextapi/Servicecontext';
import './basicotp.css';

const BasicOTPComponent = () => {
  const { bookingdata, setBookingdata , abled ,setAbled } = useServiceContext();
  const [otp, setOtp] = useState("");

  const { register } = useOtpInput({
    onInputValueChange: (otp) => setOtp(otp),

  });

  const handleInputChange = (event, inputIndex) => {
    setAbled(otp.length)


    const digit = event.target.value.replace(/\D/g, '');

    if (digit.length <= 1) {
      setOtp((prevOtp) => {
        const newOtp = prevOtp.split('');
        newOtp[inputIndex] = digit;
        
        return newOtp.join('');
      });
    }
  };

  const defaultOptions = {
    required: true,
  };

  useEffect(() => {
    if (otp.length === 4) {
      setBookingdata((prevData) => ({
        ...prevData,
        otp: otp,
      }));
    }
  }, [otp, setBookingdata]);


  return (

    <div style={{ display: 'flex', justifyContent: 'space-around', padding: '5px',  marginTop: '23px'}}>
      
      {[1, 2, 3, 4].map((index) => (
        <React.Fragment key={index}>
          
          <input
            {...register(`digit-${index}`, defaultOptions)}
            style={{ width: '50px', height: '50px', textAlign: 'center', border: '1px solid #DEDEDE' , borderRadius: '9px' }}
            value={otp[index - 1] || ''}
            onChange={(event) => handleInputChange(event, index - 1)}
            type="number"
          />

          {index < 4 && <span className="otp-between" > - </span>}

        </React.Fragment>
      ))}
    </div>
  );
};

export default BasicOTPComponent;
