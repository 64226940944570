import React, { useState } from 'react';
import './businesstab.css'
import ServiceCategories from '../Servicecatagories/Servicecatagories';
import { json, useNavigate } from 'react-router-dom';
import BusinessOffer from '../BusinessOffer/BusinessOffer';
import { useServiceContext } from '../contextapi/Servicecontext';
import PhotoGallery from '../../BusinesGallery/Gallery';
import About from './about';
import AppointmentCard from '../../My Appointments/Appointments';
export default function Businesstab() {

    const { activeTab, setActiveTab} = useServiceContext()

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        // sessionStorage.setItem('selectedServices',[]);
        sessionStorage.setItem('selectedServices', JSON.stringify([]));
        sessionStorage.setItem('serviceIcons', JSON.stringify([]));


        
    };

    const navigate = useNavigate();


    return (
        <div className="border border-gray bg-white p-3">

            <div className="row">
            <div className="col-md-12">
                <div className="d-flex align-items-center mb- border-bottom">
                    
                    <div  onClick={() => handleTabClick('about')}> <span onClick={() => navigate(-1)}><i class="fa-solid fa-arrow-left"></i>  </span>
                   
                    </div>
                    <div className={`tab ${activeTab === 'about' ? 'active' : ''}`} onClick={() => handleTabClick('about')}>About</div>
                    <div className={`tab ${activeTab === 'service' ? 'active' : ''}`} onClick={() => handleTabClick('service')}>Services</div>
                    <div className={`tab ${activeTab === 'photos' ? 'active' : ''}`} onClick={() => handleTabClick('photos')}>Photos</div>
                    <div className={`tab ${activeTab === 'offer' ? 'active' : ''}`} onClick={() => handleTabClick('offer')}>Offers</div>
                    {/* <div className={`tab ${activeTab === 'appointments' ? 'active' : ''}`} onClick={() => handleTabClick('appointments')}>Appointments</div> */}
                    
                </div>
              </div>  
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="content-section">
                        {activeTab === 'about' && <div> <About/></div>}
                        {activeTab === 'service' && <div> <ServiceCategories /> </div>}
                        {activeTab === 'photos' && <div> <PhotoGallery/></div>}
                        {activeTab === 'offer' && <div> <BusinessOffer/></div>}
                        {activeTab === 'appointments' && <div> <AppointmentCard/></div>}

                    </div>
                </div>
            </div>
        </div>
    );
}
