import React from "react";
import './bookingconfirmed.css'
import { Link } from 'react-router-dom';
import { useServiceContext } from "../contextapi/Servicecontext";

const BookingSuccessModal = ({ onClose, message,tokenNumber ,referenceNumber, salonName, locationId, qrcodeImage}) => {


  const { language,
    changeLanguage,
    translations,moduleType } = useServiceContext();

 const param = JSON.parse(sessionStorage.getItem('url'))
 const dynamicUrl = `/${param?.city}/${param?.salonName}/${param?.locationId}`;

  return (
    <div className="booking-success-modal" style={{ marginLeft: '72px' }}>
      <div className="sucessful-modal">
        <div style={{ width: "225px" }}>
          <div style={{ marginLeft: '246px' }}>
            <Link to ="/l" onClick={onClose}>
              <span><i className="fas fa-times my-icon"></i></span>
            </Link>
          </div>
          <div>
            <i className="fa-regular fa-circle-check"></i>
          </div>
         { referenceNumber && <div><div style={{ textAlign: 'center' }}>
            <div>{message}</div>
            <div> {translations.reference} {referenceNumber}</div>
  

            </div>
                 
          <div className="qr-code"> <img src = {qrcodeImage}  alt='qrcode'  width = '100px'/></div>
          </div> }

        <div className="track"> <span className="track"> {translations.track} </span></div>

        </div>
        <div className="row  ggl-appl"   >
       
  <span>

    <a href="https://play.google.com/store/apps/details?id=com.schedmad.whizzq&hl=en&gl=US&pli=1">
      <img
        src="https://whizzq.app/wp-content/uploads/2020/05/Layer-4.png"
        alt=""
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </a>

  </span>

  <span>

    <a href="https://apps.apple.com/in/app/whizzq-realtime-booking-app/id1527037346">
      <img
        src="https://whizzq.app/wp-content/uploads/2020/05/Layer-5.png"
        alt=""
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </a>

  </span>
</div>
        
      </div>
     

    </div>
  );
};

export default BookingSuccessModal;
