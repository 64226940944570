import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { useState } from 'react';

const clientId = '467877011229-mccphub3n3ecojdcdbrba4lkjrr26m61.apps.googleusercontent.com';


const GoogleLoginButton = ({setLoginModalVisibility}) => {

  const handleLoginSuccess = async (response) => {
    const decodedToken = jwtDecode(response.credential);
  
     const api = process.env.REACT_APP_API_URL + 'LoginOrRegisterWithSocial'
    try {
      const res = await fetch(api, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sub: decodedToken.sub,
          email: decodedToken.email,
          name:  decodedToken.given_name + " " +decodedToken.family_name,
          for_what: "google",
          picture: decodedToken.picture,
        }),
      });
  
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
            

      const data = await res.json();

      // Set cookies
      Cookies.set('bookingToken', data?.token, { expires: 30 });
      Cookies.set('firstname', decodedToken?.family_name, { expires: 30 });
      Cookies.set('lastname', decodedToken?.given_name, { expires: 30 });
      Cookies.set('mobile', data?.mobile, { expires: 30 });
  
      // Save data to localStorage
      localStorage.setItem('userId', JSON.stringify(data?.userId));
      localStorage.setItem('mobileNumber', JSON.stringify(data?.mobile));
      if (typeof setLoginModalVisibility == 'function') {
        setLoginModalVisibility(false);
      }
      

    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };
  

  const handleLoginFailure = (error) => {
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onFailure={handleLoginFailure}
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
