// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  
  .my-icon{
    color: #1d3557;
    font-size: 20px;
    padding-left: 3px;
  }
  .body-bg{
    background-color: #f7f7f7;
  }

   
   .top{

    margin-top: 80px;
  }
  /* Medium devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .top{

      margin-top: 90px;

  }
  }


  @media (max-width: 575.98px) { 
   .top{

       margin-top: 120px;

   }
}
 
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;IAC1B,4BAA4B;EAC9B;;EAEA;IACE,cAAc;IACd,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,yBAAyB;EAC3B;;;GAGC;;IAEC,gBAAgB;EAClB;EACA,8CAA8C;EAC9C;IACE;;MAEE,gBAAgB;;EAEpB;EACA;;;EAGA;GACC;;OAEI,iBAAiB;;GAErB;AACH","sourcesContent":[".app-container {\n    display: grid;\n    grid-template-columns: 1fr;\n    grid-template-rows: auto 1fr;\n  }\n  \n  .my-icon{\n    color: #1d3557;\n    font-size: 20px;\n    padding-left: 3px;\n  }\n  .body-bg{\n    background-color: #f7f7f7;\n  }\n\n   \n   .top{\n\n    margin-top: 80px;\n  }\n  /* Medium devices (tablets, less than 992px) */\n  @media (max-width: 991.98px) {\n    .top{\n\n      margin-top: 90px;\n\n  }\n  }\n\n\n  @media (max-width: 575.98px) { \n   .top{\n\n       margin-top: 120px;\n\n   }\n}\n \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
