import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useParams, json } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import Header from "./Components/Header/Header";
import Bothcomponent from "./Components/Both/Bothcomponent";
import Bothcomponent2 from "./Components/Both/Bothcomponent2";
import Bothcomponent3 from "./Components/Both/Bothcomponent3";
import { ServiceProvider } from "./Components/contextapi/Servicecontext";
import BusinessListing from "./Components/businesslisting/Businesslisting";
import './App.css'
import Banner from "./Components/businesslisting/Banner";
import Cookies from "js-cookie";
import AppointmentCard from "./My Appointments/Appointments";
import TrackApponitment from "./My Appointments/TrackAppointment";
import Details from "./My Appointments/Details";
import UserProfile from "./Components/Userprofile/UserProfile";
import CityLandingpage from "./Components/Citylandingpage/CityLandingpage";
function App() {

    ReactGA.initialize('65647425');

    



    const storedSelectedServices = JSON.parse(sessionStorage.getItem('selectedServices'));

    if (storedSelectedServices == null) {

        sessionStorage.setItem('selectedServices', JSON.stringify([]));
        sessionStorage.setItem('serviceIcons', JSON.stringify([]));

    }

    const [locationId, setLocationId] = useState(null);
    const { city, salonName } = useParams();
    const [flow, setFlow] = useState(null);
    const [showCategory, setShowCategory] = useState(false);


    const category_url = window.location.pathname;


    const urlParams = new URLSearchParams(window.location.search);

    const rwgToken = urlParams.get('rwg_token');
    const serviceId = urlParams.get('serviceId')

    if (serviceId != undefined) {

        sessionStorage.setItem('serviceId', serviceId)
    }


    if (rwgToken) {
        var merchantid = 838;
        const url = window.location.pathname;

        var cookiestring = "_rwg_token=" + rwgToken + ";_merchant_id=" + merchantid + ";max-age=2592000;domain=stagingbiz.whizzq.app;path=" + url;

        Cookies.set('_rwg_token', cookiestring)
    }


    const fetchData = async (loc_id) => {

        if (loc_id !== undefined) {
            const api = process.env.REACT_APP_API_URL + 'locationDetails';
    
            try {
                const response = await fetch(api, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ 'location_id': loc_id }),
                });
    
                if (response.ok) {
                    const responseData = await response.json();
                    setFlow(responseData.locationType);
                    sessionStorage.setItem('flow', responseData.locationType);
                } else {
                    console.error('API request returned an error');
                }
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        } else {
            console.warn('loc_id is undefined, fetchData was not called.');
        }
    };
    


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname })

        const url = window.location.pathname;
        const urlParts = url.split('/');
        const lastPart = urlParts[urlParts.length - 1];
        const parts = lastPart.split('-');
        const number = parts.pop()
        const loc_id = number

        fetchData(loc_id);
        sessionStorage.setItem('locationId', loc_id)


    }, []);

    useEffect(() => {

        const category_url = window.location.pathname;

        const categoryPattern = /^\/l\/[^\/]+\/[^\/]+\/[a-zA-Z0-9-]+$/;

        if (categoryPattern.test(category_url)) {
            setShowCategory(true);
        } else {
            setShowCategory(false);
        }
    }, []);
   

    return (

        <Router>
<HelmetProvider>
            <div className="app-container ">
                <ServiceProvider>
                   

                    <Header />
                    



                    <div className="container  top px-3">

                        <div className="row g-2">
                            <Routes>
                                <Route
                                    path="/l"
                                    element={<BusinessListing />}
                                />
                                <Route path="/l/:city" element={<CityLandingpage />} />
                                {/* <Route
                                    path="/l/:city"
                                    element={<BusinessListing />}
                                /> */}

                                <Route path="/l/appointments" element={<AppointmentCard />} />
                                <Route path="/l/track/:refNo" element={<TrackApponitment />} />
                                <Route path="/l/info/:refNo" element={<Details />} />
                                <Route path="/l/userprofile" element={<UserProfile />} />
                                <Route
                                        path='/l/:city/:salonName/:locationId/timeslot'
                                        element={
                                            <Bothcomponent3 />
                                        }
                                    />

                                {showCategory ? (
                                    <>

                                        <Route
                                            path="/:categoryName/:city/:salonName/:locationId"

                                            element={

                                                flow == 1 ? <Bothcomponent3 /> :
                                                    flow == 2 ? <Bothcomponent2 /> :
                                                        flow == 0 ? <Bothcomponent /> :
                                                            null
                                            }
                                        />
                                        <Route
                                            path="/:categoryName/:city/:salonName/:locationId/staff"
                                            element={<Bothcomponent2 />}
                                        />

                                        <Route
                                            path='/:categoryName/:city/:salonName/:locationId/timeslot'
                                            element={
                                                <Bothcomponent3 />
                                            }
                                        />
                                    </>
                                ) : (<>
                                    <Route
                                        path="/:city/:salonName/:locationId"

                                        element={

                                            flow == 1 ? <Bothcomponent3 /> :
                                                flow == 2 ? <Bothcomponent2 /> :
                                                    flow == 0 ? <Bothcomponent /> :
                                                        null
                                        }
                                    />
                                    <Route
                                        path='/:city/:salonName/:locationId/staff'
                                        element={
                                            <Bothcomponent2 />
                                        }
                                    />
                                    <Route
                                        path='/:city/:salonName/:locationId/timeslot'
                                        element={
                                            <Bothcomponent3 />
                                        }
                                    />
                                </>
                                )

                                }
                                <Route
                                    path='/servicecategory'
                                    element={
                                        <Bothcomponent />
                                    }
                                />
                                {flow != undefined ?(<Route
                                        path="/:city/:salonName/:locationId"

                                        element={

                                            flow == 1 ? <Bothcomponent3 /> :
                                                flow == 2 ? <Bothcomponent2 /> :
                                                    flow == 0 ? <Bothcomponent /> :
                                                        null
                                        }
                                    />) : (<Route path="/l/:city/:categoryName" element={<BusinessListing />} />)

                                }
                                 
                             
                            </Routes>
                        </div>
                    </div>

                </ServiceProvider>
            </div>
            </HelmetProvider>
        </Router>
    );
}

export default App;
