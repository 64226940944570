import React, { useState } from 'react';
import './businessprofile.css';
import Businesstab from './businesstab';
import { useEffect } from 'react';
import { useServiceContext } from '../contextapi/Servicecontext';

export default function BusinessProfile() {

    const { businessDetails, setBusinessDetails ,setModuleType,setUrl ,setLocationName, selectedServices ,setDescription,totalservice ,setBusinessId ,setQueue,locationid , setLocationId
    } = useServiceContext();
      
    const [from , setFrom] = useState();
    const [toTime ,setToTime] = useState();
    const [isHoliday , setIsHoliday] =  useState();
    const [holidayDescription , setHolidayDescription] =  useState();




    const convertTo12HourFormat = (time24) => {
        // Split the time string into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);

        // Determine the period (AM/PM)
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

        // Format the time string
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };


    const fetchData = async () => {

        const location_id = sessionStorage.getItem('locationId')

        const api = process.env.REACT_APP_API_URL + 'locationDetails';

        try {

            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ location_id }),
            });

            if (response.ok) {

                const responseData = await response.json();
                setBusinessDetails(responseData)
                setBusinessId(responseData.locationId)
                setQueue(responseData.module)
                setLocationId(responseData.locationId)
                setDescription(responseData.listing_description)
                setUrl(responseData.listing_url)
                setModuleType(responseData.module)
                setLocationName(responseData.locationName)
                setFrom(responseData.frmTime)
                setToTime(responseData.toTime)
                setIsHoliday(responseData.isHoliday)
                setHolidayDescription(responseData.holidayDescription)
            }
            else {
                console.error('API request returned an error');
            }
        }
        catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const from12Hour = from ? convertTo12HourFormat(from) : '';
    const to12Hour = toTime ? convertTo12HourFormat(toTime) : '';

 
    useEffect(() => {

        fetchData()
    }, [])


    return (
        <>
            <div className="d-flex border border-gray bg-white p-3">
                <div className="businessprofile-image ">
                    <img  src= { businessDetails.locationImage ? businessDetails.locationImage : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'}
                        className="img-fluid me-3"
                        alt="Business Image"
                    />
                </div>
                <div className="col-md-10">
                    <div>
                        <div className="b-title">{businessDetails.locationName}</div>
                        <div className="card-address"><span > <i className="fa fa-location-dot"></i> {businessDetails.locationAddress + ',' + businessDetails.locationCity + ',' + businessDetails.locationState}</span> </div>
                      { isHoliday ?(<div>{holidayDescription}</div>) : (<div className="card-timing"><span> <i className="fa  fa-clock"></i> </span>  <span className='card-bold'>Working hours:</span> <span className="card-address">{from12Hour + " to " + to12Hour} </span> </div> ) }

                        <div className="card-timing"> <span><i className="fa-solid fa-gear"></i> </span> <span className='card-bold'> Total Services:</span><span className="card-address"> {totalservice} </span></div>
                    </div>
                </div>
            </div>
            
            
        </>
    );
}
