import React, { useEffect, useState } from 'react';
import './Offers.css';
import { useServiceContext } from '../contextapi/Servicecontext';

export default function BusinessOffer() {
  // State to store the fetched offers
  const [offersData, setOffersData] = useState([]);
  const [scIds, setScIds] = useState([]);
  const [sIds, setSIds] = useState([]);

  const { businessid, categoryIds , setCategoryIds, serviceIds , setServiceIds, setActiveTab, showOffer , setShowoffer ,translations ,offervalidDate , setOffervalidDate } = useServiceContext();

  const fetchOffers = async () => {

   const api = process.env.REACT_APP_API_URL + 'getOfferByListings'

    try {
      const response = await fetch(api, {
        method: 'POST', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({ listingId: businessid }), // JSON stringify the request body
      });

      // If the response is successful
      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        setOffersData(data); // Update the state with the fetched data
      } else {
        console.error('Failed to fetch offers:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching offers:', error);
    }
  };

  // Function to extract SC_IDs and s_ids
  const extractIds = (offerId) => {
    const foundOffer = offersData.find(offer => offer.id === offerId);
    if (foundOffer) {
      const scIds = foundOffer.services.map(service => service.SC_ID);
      const sIds = foundOffer.services.map(service => service.s_id);
      setCategoryIds(scIds);
      setServiceIds(sIds)
      
    }
  };

  // Fetch the offers when the component mounts
  useEffect(() => {
    fetchOffers();
  }, []); // Empty dependency array to ensure the effect runs only once on mount

  // Function to handle "Avail" button click
  const handleAvailButtonClick = (offerId , offer) => {
    extractIds(offerId); // Call the extractIds function with the offerId
    setActiveTab('service')
    setShowoffer(true);
    setOffervalidDate(offer)
  };

  return (
    <div>
    {offersData.length === 0 ? (
      <div className='no-offer'> No Offers Available.</div>
    ) : (
      offersData.map((offer, index) => (
        <div
          key={index}
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            margin: '10px',
            borderRadius: '12px',
          }}
          className="d-flex justify-content-between align-items-center business-offer"
        >
          <div>
            <div className="offer-title">{offer.offer_name}</div>
            <div className="offer-description mt-2">{offer.offer_detail}</div>
            <div className="offer-code mt-2"> {translations.discount_code}: {offer.offer_code} </div>
          </div>
          <div>
            <button type="button" className="btn btn-light" onClick={() => handleAvailButtonClick(offer.id ,offer)}>Avail</button>
          </div>
        </div>
      ))
    )}
  </div>
  
  );
}
