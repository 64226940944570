import React, { useState, useEffect } from 'react';
import './Appointment.css';
import { useServiceContext } from '../Components/contextapi/Servicecontext';
import { Link, useNavigate } from 'react-router-dom';
import { isPast } from 'date-fns';
import { flow } from 'lodash';
import Ratings from './Ratings';

const AppointmentCard = () => {
    const [activeTab, setActiveTab] = useState('upcoming');
    const [loading, setLoading] = useState(true);
    const { userId, setSelectedServices, translations } = useServiceContext();
    const [appointments, setAppointments] = useState([]);
    const [past, setPast] = useState([]);
    const navigate = useNavigate();
    const [selectedRefNo, setSelectedRefNo] = useState(null);

    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const savedTab = localStorage.getItem('activeTab') || 'upcoming';
        setActiveTab(savedTab);
        if (savedTab === 'upcoming') {
            fetchUpcomingAppointments();
        } else if (savedTab === 'past') {
            fetchPastAppointments();
        }
    }, []);
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        localStorage.setItem('activeTab', tab);
        if (tab === 'upcoming') {
            fetchUpcomingAppointments();
        } else if (tab === 'past') {
            fetchPastAppointments();
        }
    };

    const fetchUpcomingAppointments = async () => {
        setLoading(true);
        try {
            const userId = localStorage.getItem('userId');
            const api = process.env.REACT_APP_API_URL + 'openBookingData';

            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    server_id: userId
                })
            });

            const data = await response.json();
            if (data.status) {
                const upcomingAppointments = data.openbooking.map(booking => ({
                    refNo: booking.srt_reffrence_no,
                    tokenNo: booking.token_no,
                    BussinessLogo: booking.listing_logo || 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png',
                    BussinessName: booking.listing_title,
                    services: booking.services,
                    FirstName: booking.FirstName,
                    MiddleName: booking.MiddleName,
                    LastName: booking.LastName,
                    date: booking.date,
                    start: booking.start,
                    persons: booking.persons,
                    mobile: booking.mobile,
                    Note: booking.Note,
                    status: booking.status,
                    reffrence_number: booking.reffrence_number,
                    userId: booking.server_id,
                    payment_mode: booking.payment_mode,
                    email: booking.email,
                    durration: booking.durration,
                    TotalAmount: booking.TotalAmount
                }));
                setAppointments(upcomingAppointments);
            }
        } catch (error) {
            console.error('Error fetching upcoming appointments:', error);
        }
        setLoading(false);
    };

    const fetchPastAppointments = async () => {
        setLoading(true);
        try {
            const userId = localStorage.getItem('userId');
            const api = process.env.REACT_APP_API_URL + 'closeBookingData';
            // const api = 'http://192.168.0.81:82/api/closeBookingData'

            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    server_id: userId
                })
            });
            const data = await response.json();
            if (data.status) {
                const pastBookings = data.closebooking.map(booking => ({
                    refNo: booking.srt_reffrence_no,
                    tokenNo: booking.token_no,
                    BussinessLogo: booking.listing_logo || 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png',
                    BussinessName: booking.listing_title,
                    services: booking.services,
                    FirstName: booking.FirstName,
                    MiddleName: booking.MiddleName,
                    LastName: booking.LastName,
                    date: booking.date,
                    start: booking.start,
                    persons: booking.persons,
                    mobile: booking.mobile,
                    Note: booking.Note,
                    status: booking.status,
                    price: booking.cost,
                    reffrence_number: booking.reffrence_number,
                    userId: booking.server_id,
                    payment_mode: booking.payment_mode,
                    email: booking.email,
                    staffId: booking.server_id,
                    location_id: booking.locationCode,
                    flow: booking.locationType,
                    durration: booking.durration,
                    rating: booking.rating,
                    comment: booking.comment,
                    TotalAmount: booking.TotalAmount
                }));
                setPast(pastBookings);
            }
        } catch (error) {
            console.error('Error fetching past appointments:', error);
        }
        setLoading(false);
    };


    const handleTrackClick = (appointment) => {
        navigate(`/l/track/${appointment.refNo}`, { state: { appointment } });
    };

    const handleInfo = (appointment, isPast) => {
        navigate(`/l/info/${appointment.refNo}`, { state: { appointment, isPast } });
    };

    const handleRepeat = (appointment) => {

        const services = appointment.services.map(service => ({
            'serviceIndex': service.s_id,
            'categoryIndex': service.SC_ID,
            'category_name': service.SC_Name,
            'cost': service.cost,
            'cost_type': service.cost_type,
            'discount_cost': 0,
            'duration': service.durration,
            'location_id': appointment.location_id,
            'address_required': service.address_required,
            'service_name': service.s_name,
            'staffId': appointment.staffId
        }));

        setSelectedServices(services);

        try {
            sessionStorage.setItem('selectedServices', JSON.stringify([services]));
            sessionStorage.setItem('locationId', appointment.location_id);
            sessionStorage.setItem('staffList', appointment.staffId);
            sessionStorage.setItem('flow', appointment.flow);
            sessionStorage.setItem('defaultnumber', services[0].categoryIndex);
        } catch (error) {
            console.error('Error storing data in sessionStorage:', error);
        }
    };

    const handleRatingsModel = (refNo) => {
        setShowModal(true)
        setSelectedRefNo(refNo)

    }
    const handleCloseModal = () => {
        setShowModal(false);
    };


    return (
        <div className="tabs-container">
            <div className="tabs">
                <span
                    className={`tab ${activeTab === 'upcoming' ? 'active' : ''}`}
                    onClick={() => handleTabChange('upcoming')}
                >
                    UPCOMING
                </span>
                <span
                    className={`tab ${activeTab === 'past' ? 'active' : ''}`}
                    onClick={() => handleTabChange('past')}
                >
                    PAST
                </span>
            </div>
            <div className="tab-content">
                {loading ? (
                    <div><div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        height: '56px',
                        width: '56px',
                        animation: 'rotate 2s linear infinite',
                        marginTop: '120px'
                    }} className='loaders loader-overlay'>
                    </div>
                        <div className='loaders-overlay' style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}>
                            <img src="https://whizzdatalocal.s3.us-west-2.amazonaws.com/whizzq-icon/whizzqlogo_dec23.png" width='120px' alt="Loading..." />
                        </div></div>
                ) : (
                    <div className="main-container">
                        {activeTab === 'upcoming' && (
                            <div className="row">
                                {appointments.length === 0 ? (
                                    <div className="no-appointment-message">{translations["No Scheduled Appointments"]}.</div>
                                ) : (
                                    appointments.map((appointment, index) => {
                                        const totalAmount = appointment?.services?.reduce((total, service) => {
                                            const cost = parseFloat(service.cost);
                                            return total + (isNaN(cost) ? 0 : cost);
                                        }, 0);


                                        return (
                                            <div key={index} className="col-md-6">
                                                <div className="appointment-card mb-4">
                                                    <div className="content">
                                                        <b><span>Ref/No: {appointment.refNo}</span></b>
                                                        <div className="info-btn" onClick={() => handleInfo(appointment, false)}><button className="info"><i className="fa-solid fa-circle-info"></i></button></div>
                                                        {appointment.tokenNo && (
                                                            <b><span className="token" style={{ color: 'green', padding: '35px' }}>T/No: {appointment.tokenNo}</span></b>
                                                        )}
                                                    </div>
                                                    <div className="card-content">
                                                        <div className="hospital-info">
                                                            <div className="hospital-logo">
                                                                <img src={appointment.BussinessLogo} alt="Business Logo" />
                                                            </div>
                                                            <div className="info-details">
                                                                <div className="hospital-name">{appointment.BussinessName}</div>
                                                                <div className="hospital-time">
                                                                    <span className="appointment-date">{appointment.date}</span>
                                                                    <span className="appointment-time">{appointment.start}</span>
                                                                    <span className="appointment-status">{appointment.status}</span><br />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <span className="appointment-person">Persons: {appointment.persons}</span>
                                                        <div className="appointment-details">
                                                            <div className="service-status-container">
                                                                <div className="servicee-names">
                                                                    {appointment?.services?.map(service => (
                                                                        <div key={service.s_id} className="servicee-name">{service.s_name}</div>
                                                                    ))}
                                                                </div>
                                                                <div className="price-repeat">
                                                                    <div className="repeat-button">
                                                                        <button className="rpt-btn" onClick={() => handleTrackClick(appointment)}>Track</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        )}
                        {activeTab === 'past' && (
                            <div className="row">
                                {past.length === 0 ? (
                                    <div className="no-appointment-message">{translations["No Scheduled Appointments"]}.</div>
                                ) : (
                                    past.map((appointment, index) => {
                                        const totalAmount = appointment?.services?.reduce((total, service) => {
                                            const cost = parseFloat(service.cost);
                                            return total + (isNaN(cost) ? 0 : cost);
                                        }, 0);

                                        const encodedBussinessName = appointment.BussinessName

                                        const location_id = appointment.location_id;
                                        const place = localStorage.getItem('place');

                                        return (
                                            <div key={index} className="col-md-6">
                                                <div className="appointment-card mb-4">
                                                    <div className="content">
                                                        <b><span>Ref/No: {appointment.refNo}</span></b>
                                                        <div className="info-btn" onClick={() => handleInfo(appointment, true)}><button className="info"><i className="fa-solid fa-circle-info"></i></button></div>
                                                        {appointment.tokenNo && (
                                                            <b><span className="token" style={{ color: 'green', padding: '35px' }}>T/No: {appointment.tokenNo}</span></b>
                                                        )}
                                                    </div>

                                                    <div className="card-content">
                                                        <div className="hospital-info">
                                                            <div className="hospital-logo">
                                                                <img src={appointment.BussinessLogo} alt="Business Logo" />
                                                            </div>
                                                            <div className="info-details">
                                                                <div className="hospital-name">{appointment.BussinessName}</div>
                                                                <div className="hospital-time">
                                                                    <span className="appointment-date">{appointment.date}</span>
                                                                    <span className="appointment-time">{appointment.start}</span>
                                                                    <span className="appointment-status">{appointment.status}</span><br />
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='rating-details'>
                                                        <span className="appointment-person">Persons: {appointment.persons}</span>
                                                        {/* { appointment.rating != undefined && appointment.comment != undefined && (appointment.rating !== null || appointment.comment !== null) && (
                            <>
                                {appointment.rating !== null && (
                                    <>
                                        <span className="label">{translations.Rating}</span>
                                        <span className="value">
                                            {[...Array(5)].map((_, index) => (
                                                <span key={index} className={index < appointment.rating ? 'stars filled' : 'stars'}>
                                                    ★
                                                </span>
                                            ))}
                                        </span>
                                    </>
                                )}
                                {appointment.comment !== null && (
                                    <>
                                        <span className="label">{translations.Comment}</span>
                                        <span className="value">{appointment.comment}</span>
                                    </>
                                )}
                            </>
                        )} */}
                                                        </div>
                                                        
                                                        <div className="appointment-details">
                                                            <div className="service-status-container">
                                                                <div className="servicee-names">
                                                                    {appointment?.services?.map(service => (
                                                                        <div key={service.s_id} className="servicee-name">{service.s_name}</div>
                                                                    ))}
                                                                </div>
                                                                <div className="price-repeat">
                                                                    <div className="price">
                                                                        ₹{appointment.TotalAmount}
                                                                    </div>
                                                                    <div className="repeat-button">
                                                                        <Link
                                                                            to={{
                                                                                pathname: `/l/Vadodara/${encodedBussinessName}/${location_id}/timeslot`,
                                                                                state: {
                                                                                    location: location_id,
                                                                                    BussinessName: appointment.BussinessName,
                                                                                    place: place,
                                                                                    staffId: appointment.staffId,
                                                                                    services: appointment.services
                                                                                }
                                                                            }}
                                                                        >

                                                                            <button className="rpt-btn" onClick={() => handleRepeat(appointment)}>Repeat</button>
                                                                        </Link>
                                                                        {/* {appointment.rating === null && (
                                                                            <button className='ratings' style={{ marginLeft: '5px' }} onClick={() => handleRatingsModel(appointment.refNo)}>
                                                                                Ratings
                                                                            </button>
                                                                        )} */}
                                                                        {appointment.rating === null && appointment.status.toLowerCase() === "served" && (
                                                                            <button className='ratings' style={{ marginLeft: '5px' }} onClick={() => handleRatingsModel(appointment.refNo)}>
                                                                                Ratings
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Ratings show={showModal} onClose={handleCloseModal} reffrence_number={selectedRefNo} />
        </div>
    );
};

export default AppointmentCard;
