import React, { useEffect } from 'react'
import { useState } from 'react';
import Selectslot from '../Selectslot/Selectslot';
import Billingprice from '../Billingprice/Billingprice';
import { useServiceContext } from '../contextapi/Servicecontext';
import LoginModal from '../BookAppointment/Loginmodal';



export default function Bothcomponent3() {


  const [isLoginModalVisible, setLoginModalVisibility ] = useState(false);

  const {businessDetails , bookingdata, selectedServices , bookingbutton , setBusinessDetails} = useServiceContext();
  const uniqueServiceIndexSet = new Set();

  selectedServices.forEach(service => {
    if (service.serviceIndex !== undefined) {
      uniqueServiceIndexSet.add(service.serviceIndex);

    }
  });
  
  
  const serviceIndexArray = selectedServices
    .filter(service => service.serviceIndex !== undefined)
    .map(service => service.serviceIndex);
  
  const totalservice = serviceIndexArray.length;

  const buttonStyle = {

    backgroundColor: bookingbutton?   'lightcoral' :  '#E63946',
    border: '1px solid black'

  };


  const fetchData = async () => {

    const location_id = sessionStorage.getItem('locationId')

    const api = process.env.REACT_APP_API_URL + 'locationDetails';

    try {

        const response = await fetch(api, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ location_id }),
        });

        if (response.ok) {

            const responseData = await response.json();
            setBusinessDetails(responseData)
        }
        else {
            console.error('API request returned an error');
        }
    }
    catch (error) {
        console.error('An error occurred while fetching data:', error);
    }
};



useEffect(() => {

    fetchData()
}, [])
        

    const handleBookAppointment = () => {
      setLoginModalVisibility(true)


    }




  return (
    <> 
        <div className='col-md-8 mt-3'>
        <div className="d-sm-block d-md-none mb-3">
      <div className='card'>
                  <div className='card-body'>
                    <div className='d-flex align-items-center'>
                      <div>
                      <img
    src={businessDetails.locationImage ? businessDetails.locationImage : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'}
    alt="Your Image"
    className='bill-branch-img'
  />                      </div>
                      <div>
                          <div className=' billing-head'>{businessDetails.locationName} </div>
                          <div className='billing-subhead'> {} </div>
                      </div>
                  </div>
                  </div>
                </div>
            </div>
          <div className='card'>
            <div className='card-body'>
            <Selectslot/>
            </div>
          </div>
            
        </div>
        <div className='col-md-4 mt-3'>
        <div className='card d-none d-md-block'>
            <div className='card-body  '> 

            <Billingprice />

            </div>
          </div>
          
          
        </div>
        <div className="tab-fixed d-sm-block d-md-none">
                <div className='d-flex justify-content-between p-2'>
                  <div className='col-md-6'>
                      <p className='num-service'><span className='fw-bold'>{totalservice}</span> Service Added</p>
                  </div>
                  <div className='col-md-6 '>
                    <div className='d-flex align-items-center'>
                    <span className='num-service-price'><i className="fa-solid fa-indian-rupee-sign"></i> {bookingdata.totalCost}</span>
                      <button type="button" className="btn-sm-nxt float-end" onClick={() => handleBookAppointment()}  
                             disabled={bookingbutton} style={buttonStyle}>Book</button>
                    </div>

                  </div>
                </div>
          </div>   
        <div>
          {isLoginModalVisible && (
        <LoginModal onClose={() => setLoginModalVisibility(false)}/> 
      )}

          
        </div>
    </>
  
  )
}
   