// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-details {

    font-family: 'Poppins' !important;  
    font-weight: 400 !important; 
    font-size: 18px !important;
}

.user-update-btn{
    background-color: #1D3557 !important;
    font-family: 'Poppins';
    color: #fff !important;
}

.Toastify__toast-body{

    color: #1D3557;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Userprofile/userprofile.css"],"names":[],"mappings":"AAGA;;IAEI,iCAAiC;IACjC,2BAA2B;IAC3B,0BAA0B;AAC9B;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;IACtB,sBAAsB;AAC1B;;AAEA;;IAEI,cAAc;AAClB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');\n\n\n.user-details {\n\n    font-family: 'Poppins' !important;  \n    font-weight: 400 !important; \n    font-size: 18px !important;\n}\n\n.user-update-btn{\n    background-color: #1D3557 !important;\n    font-family: 'Poppins';\n    color: #fff !important;\n}\n\n.Toastify__toast-body{\n\n    color: #1D3557;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
