// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two photos in each row */
  gap: 10px; /* Reduced space between photos */
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
}

.photo-gallery a {
  display: block;
}

.photo-gallery img {
  width: 100%;
  height: auto;
}

.no-photo {
  font-family: 'Poppins';
}

.load-more-button {
  display: block; 
  margin: 5px auto; /* Reduced margin */
  padding: 6px 10px; /* Add padding for better touch interaction */
  background-color: #1D3557; /* Example background color */
  color: #fff; /* Example text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Add some border radius */
  cursor: pointer; /* Show cursor pointer */
}

/* Media query for mobile view */
@media (max-width: 767px) {
  .photo-gallery {
    grid-template-columns: 1fr; /* One photo per row */
    max-height: none; 
    margin-bottom: 10px; /* Reduced margin */
  }

  .photo-gallery img {
    max-height: none; /* Remove max-height for mobile view */
  }
}
`, "",{"version":3,"sources":["webpack://./src/BusinesGallery/gallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC,EAAE,2BAA2B;EAClE,SAAS,EAAE,iCAAiC;EAC5C,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,gBAAgB,EAAE,mBAAmB;EACrC,iBAAiB,EAAE,6CAA6C;EAChE,yBAAyB,EAAE,6BAA6B;EACxD,WAAW,EAAE,uBAAuB;EACpC,YAAY,EAAE,kBAAkB;EAChC,kBAAkB,EAAE,2BAA2B;EAC/C,eAAe,EAAE,wBAAwB;AAC3C;;AAEA,gCAAgC;AAChC;EACE;IACE,0BAA0B,EAAE,sBAAsB;IAClD,gBAAgB;IAChB,mBAAmB,EAAE,mBAAmB;EAC1C;;EAEA;IACE,gBAAgB,EAAE,sCAAsC;EAC1D;AACF","sourcesContent":[".photo-gallery {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr); /* Two photos in each row */\n  gap: 10px; /* Reduced space between photos */\n  overflow-x: hidden;\n  overflow-y: auto;\n  max-height: 400px;\n}\n\n.photo-gallery a {\n  display: block;\n}\n\n.photo-gallery img {\n  width: 100%;\n  height: auto;\n}\n\n.no-photo {\n  font-family: 'Poppins';\n}\n\n.load-more-button {\n  display: block; \n  margin: 5px auto; /* Reduced margin */\n  padding: 6px 10px; /* Add padding for better touch interaction */\n  background-color: #1D3557; /* Example background color */\n  color: #fff; /* Example text color */\n  border: none; /* Remove border */\n  border-radius: 5px; /* Add some border radius */\n  cursor: pointer; /* Show cursor pointer */\n}\n\n/* Media query for mobile view */\n@media (max-width: 767px) {\n  .photo-gallery {\n    grid-template-columns: 1fr; /* One photo per row */\n    max-height: none; \n    margin-bottom: 10px; /* Reduced margin */\n  }\n\n  .photo-gallery img {\n    max-height: none; /* Remove max-height for mobile view */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
