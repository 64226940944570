import React, { useEffect , useState} from 'react';
import { Link, useParams , useLocation } from 'react-router-dom';
import { useServiceContext } from '../contextapi/Servicecontext';

import ServiceCategories from '../Servicecatagories/Servicecatagories';
import Billingprice from '../Billingprice/Billingprice';
import BusinessListing from '../businesslisting/Businesslisting';
import BusinessProfile from '../businessprofile/Businessprofile';
import Businesstab from '../businessprofile/businesstab';

export default function Bothcomponent() {

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(true); // Set isOpen to true by default


  

  let location = useLocation();

  const { businessDetails, bookingdata, selectedServices, setStaffLists, language, changeLanguage, translations , showstaff } = useServiceContext();

  const { city, area, salonName, salonId } = useParams();

  const uniqueServiceIndexSet = new Set();



  selectedServices.forEach(service => {
    
    if (service.serviceIndex !== undefined) {
      uniqueServiceIndexSet.add(service.serviceIndex);
    }
  });
         
  const serviceIndexArray = selectedServices
    .filter(service => service.serviceIndex !== undefined)
    .map(service => service.serviceIndex);

  const totalservice = serviceIndexArray.length;


  const serviceIndexCount = selectedServices.filter(service => service.serviceIndex !== undefined).length;

  const isNextButtonEnabled = serviceIndexCount > 0;

  const buttonStyle = {

    backgroundColor: isNextButtonEnabled ? '#1D3557' : '#4A5D78'

  };

  const ServiceId = selectedServices.map((service) => service.serviceIndex).filter(index => index !== undefined)

  const fetchStaff = async () => {

    const api = process.env.REACT_APP_API_URL + 'getStaffList';

    try {
      const response = await fetch(api, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ServiceId }),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.status === 'true') {



          const staffList = responseData.StaffList.map((staff) => {

            return {

              ...staff,
              imagePath: responseData.imagePath, // Add the image path
              profilePic: staff.profilePic || responseData.defaultProfilePic, // Use profilePic or defaultProfilePic
            };
          });

          setStaffLists(staffList);

        } else {

          console.warn('No staff is working for the selected service');
        }
      } else {

        const errorData = await response.json();
        console.error('API request failed:', errorData.error_msg);
      }
    } catch (error) {

      console.error('An error occurred while fetching data:', error);
    }
  };


  function handlestaff() {


    fetchStaff()
  }

       
 




  return (

    <>
      <div className='col-md-12'>

            <BusinessProfile/>

      </div>
      <div className='col-md-8 '>
           
          <Businesstab />
             </div>
          <div className='col-md-4' >
        <div className='card d-none d-md-block'>
          <div className='card-body  '>
            <Billingprice city={city} area={area} salonName={salonName} salonId={salonId} />
            
          </div>
        </div>
      </div>
      <div className="tab-fixed d-sm-block d-md-none">
        <div className='d-flex justify-content-between p-2'>
          <div className='col-md-6'>
            <p className='num-service'><span className='fw-bold'>{totalservice}</span>{translations.serviceadded}</p>
          </div>
          <div className='col-md-6 '>
            <div className='d-flex align-items-center'>

              <span className='num-service-price'><i className="fa-solid fa-indian-rupee-sign"></i> {bookingdata.totalCost}</span>
              < Link to={`${location.pathname}/staff` }><button type="button" className="btn-sm-nxt float-end" disabled={!isNextButtonEnabled} style={buttonStyle}

                onClick={handlestaff} >{translations.next }</button>  </Link>

            </div>

          </div>
        </div>
      </div>
      


    </>

  );
}
